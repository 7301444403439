



















































import { Component, Vue } from 'vue-property-decorator';
import { Fragment } from 'vue-fragment';

import { DispatchModule } from '@/store/index';
import ExportFilters from '../ExportFilters/ExportFilters.vue';
import { DispatchType } from '../../models/dispatch.model';
import { DispatchService } from '@/shared/services/dispatch/dispatch.service';
import { WorkOrderService } from '@/shared/services/wo/wo-import.service';
import { ToastHelper } from '@/utils/toast.util';
import {
  convertDateFieldToAPIFormat,
  convertAPIToFormat,
  convertAPIToViewFullFormat,
  DATE_API_FORMAT
} from '@/utils/date.util';
import Loading from '@/shared/components/Loading.vue';
import WoBaseModal from '@/pages/Main/components/WoBaseModal.vue';
import {
  ValidationService,
  stringCommonRule
} from '@/shared/services/validation/validation.service';
import { isClickedOnInput } from '@/shared/models/wo.model';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import {
  UnavailableTableHeaders,
  UnavailableCategoriesHeaders
} from '../models/tables/unavailable';
import {
  FilterConditions,
  FilterInput,
  TransformFiltersToJson
} from '@/shared/services/filter/filter.service';
import { PermissionsService } from '@/shared/services/permission/permission.service';
import { AdminConfService } from '@/shared/services/admin-conf/admin-conf.service';

@Component({
  components: {
    Fragment,
    ExportFilters,
    WoBaseModal,
    Loading,
    TmsTable
  }
})
export default class Unavailable extends Vue {
  convertAPIToFormat = convertAPIToFormat;
  convertAPIToViewFullFormat = convertAPIToViewFullFormat;
  dispatchModule = DispatchModule;

  tabIndex = 0;
  woModalId = 'wo-base-modal';
  contextMenuOptions = [
    {
      name: this.$i18n.t('contextMenu.detailInfo'),
      slug: 'detail-info-modal',
      index: 0
    },
    {
      name: this.$i18n.t('contextMenu.billingInfo'),
      slug: 'billing-info-modal',
      index: 1
    },
    {
      name: this.$i18n.t('contextMenu.document'),
      slug: 'document-modal',
      index: 2
    },
    {
      name: this.$i18n.t('contextMenu.memo'),
      slug: 'memo-modal',
      index: 3
    },
    {
      name: this.$i18n.t('contextMenu.tracking'),
      slug: 'tracking-modal',
      index: 4
    }
  ];

  tableKey = true;
  loading = false;
  currentPage = 1;
  routeName = 'export';
  selectedWo = {};
  isShown = {
    eq: true,
    info: true,
    book: true,
    loc: true,
    geo: true,
    sched: true
  };
  options = {
    pool: [
      { value: 'POOL', text: 'POOL' },
      { value: 'NGL', text: 'NGL' },
      { value: 'AIM', text: 'AIM' },
      { value: 'APL', text: 'APL' },
      { value: 'CS', text: 'CHINASHIPPING' },
      { value: 'CSNA', text: 'CSNA' },
      { value: 'DCLI', text: 'DCLI' },
      { value: 'EG', text: 'EVERGREEN' },
      { value: 'FLEXI', text: 'FLEXI' },
      { value: 'TNWA', text: 'TNWA' },
      { value: 'POP', text: 'POP' },
      { value: 'HD', text: 'HD' },
      { value: 'LABP', text: 'LABP' },
      { value: 'MTSN', text: 'MATSON' },
      { value: 'TAXZ', text: 'TAXZ' },
      { value: 'WCCP', text: 'WCCP' },
      { value: 'YM', text: 'YANGMING' },
      { value: 'GCCP', text: 'GCCP' },
      { value: 'GACP', text: 'GACP' },
      { value: 'KLINE', text: 'K-LINE' },
      { value: 'MOL', text: 'MOL' },
      { value: 'TPSP', text: 'TPSP' },
      { value: 'SEA', text: 'SEALAND' }
    ],
    liveDrp: [
      { value: 'SELECT', text: 'Select' },
      { value: 'DROP', text: 'Drop' },
      { value: 'LIVE', text: 'Live' }
    ]
  };

  validationRules = {};
  validationService = new ValidationService(this.validationRules);

  categoryTableHeaders = UnavailableCategoriesHeaders;
  columnHeaders = UnavailableTableHeaders(AdminConfService.chassisPool);

  disabled = false;

  constructor() {
    super();
  }

  created() {
    this.disabled = PermissionsService.can(
      'DSP / MAIN / BC DETAIL',
      'EXP',
      'View'
    );
  }

  async onPaginationChange() {
    await this.dispatchModule.search({
      dispatchType: DispatchType[this.routeName.substr(0, 3).toUpperCase()],
      category: this.$route.name.replace(`${this.routeName}-`, '')
    });
  }

  changeIsShownHandler(param) {
    this.isShown[param] = !this.isShown[param];
  }

  validateItem(item: any, itemName: string) {
    return this.validationService.checkItemValidity(item, itemName);
  }

  validationClass(item: any, itemName: string) {
    return this.validationService.validationClass(item, itemName);
  }

  get editableData() {
    return this.dispatchModule.dispatches;
  }

  get isLastPage(): boolean {
    return !this.dispatchModule.meta.lastKey;
  }

  onAction({ key, data }: { key: string; data: any }) {
    switch (key) {
      case 'save':
        this.saveUnavailable(data, data.orderNumber);
        break;
      case 'export':
        this.importUnavailable(data.id);
        break;
      case 'chassisPool':
      case 'dropLive':
        break;
      default:
        this.openDetailModal(data);
    }
  }

  openDetailModal(selectedData, index = 0, event?) {
    if (event && isClickedOnInput(event)) {
      return;
    }
    this.selectedWo = selectedData;
    this.tabIndex = index;
    this.$bvModal.show('wo-base-modal');
  }

  rowRightClicked(item: any, index, event) {
    event.preventDefault();
    const contextMenu: any = this.$refs.contextMenu;
    contextMenu.showMenu(event, item);
  }

  onContextMenuOptionSelect(event: {
    item: any;
    option: { name: string; slug: string; index: number };
  }) {
    this.openDetailModal(event.item, event.option.index);
  }

  async saveUnavailable(item, orderNumber) {
    if (this.validationService.checkAllItemsValidity(item)) {
      const objToSave = {
        bookingNumber: item.bookingNumber,
        streetTurn:
          item.streetTurn === 'Invalid date'
            ? (item.streetTurn = null)
            : convertDateFieldToAPIFormat(item.streetTurn, DATE_API_FORMAT)
      };

      this.loading = true;

      try {
        await DispatchService.saveRequest(
          DispatchType[this.routeName.substr(0, 3).toUpperCase()],
          this.$route.name.replace(`${this.routeName}-`, ''),
          orderNumber,
          objToSave
        );
        ToastHelper.show(
          `${this.routeName}/${this.$route.name.replace(
            `${this.routeName}-`,
            ''
          )}`,
          'Save successful',
          5000,
          'success'
        );
      } catch (err) {
        ToastHelper.show(
          `${this.routeName}/${this.$route.name.replace(
            `${this.routeName}-`,
            ''
          )}`,
          err.message,
          5000,
          'danger'
        );
      }

      this.loading = false;
      this.tableKey = !this.tableKey;

      console.log(objToSave);
    } else {
      console.log('error!');
    }
  }

  async importUnavailable(id) {
    this.loading = true;

    await WorkOrderService.exportOrder('imports', id, 'dispatch');

    this.loading = false;
  }

  async searchBooks(startWith: string) {
    const filters = [
      new FilterInput({
        condition: FilterConditions.BeginsWith,
        search: startWith,
        field: 'id'
      })
    ];

    const data = await DispatchService.searchRequest(
      DispatchType.EXP,
      'booking',
      TransformFiltersToJson(filters)
    );

    return data.data;
  }

  rowClass(item) {
    if (!item) return;

    if ((item.cfFile && item.cfsFile) || item.cfFile) {
      return 'light-red-row';
    } else if (item.cfsFile) {
      return 'green-row';
    }
  }

  async search() {
    try {
      await DispatchModule.search({
        dispatchType: DispatchType[this.routeName.substr(0, 3).toUpperCase()],
        category: this.$route.name.replace(`${this.routeName}-`, '')
      });
    } catch (err) {
      ToastHelper.show(
        `${this.routeName}/${this.$route.name.replace(
          `${this.routeName}-`,
          ''
        )}`,
        err.message,
        5000,
        'danger'
      );
    }
  }
}
